@import '../../../assets/styles/colors.scss';
@import '../../../assets/styles/mixins.scss';

.products {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    padding-top: 56px;
    padding-bottom: 30px;

    @media screen and (max-width: 991px) {
        grid-template-columns: repeat(2, 1fr);
    }
    @media screen and (max-width: 767px) {
        grid-template-columns: 1fr;
    }
    @media screen and (max-width: 450px) {
        margin: 0 auto;
    }

    .product {
        display: flex;
        flex-direction: column;
        align-self: start;
        border: 1px solid $main-products-border;
        margin: 1em;

        &-img {
            width: 100%;
            padding: 2em;
        }

        &-header {
            @include text(18px, 600, $text-dark, 2);
            width: 90%;
            margin: 1em;
        }

        &-text {
            width: 90%;
            margin: 1em;
            @include text(14px, 400, $text-light-gray, 2);
        }

        &-link {
            margin: 1em 1em 3em 1em;
            border: 2px solid $bgc-blue;
            border-radius: 8pt;
            width: max-content;
            padding: 15px;
            min-width: 128px;
            text-align: center;
            @include text (12px, 400, $text-blue, 1.5);
        }
    }
}

// #polycamera {
//     padding: 0;
// }