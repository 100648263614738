@import '../../assets/styles/colors.scss';
@import '../../assets/styles/mixins.scss';

.call.contacts {
    display: none;
}

.call {
    background-color: $bgc-blue;
    
    &-text {
        display: flex;
        justify-content: space-between;
        padding: 33px 0;

        @media screen and (max-width: 991px) {
            flex-direction: column;
            justify-content: center;
            align-items: center;
        }
    }

    &-header {
        @include text (37px, 300, $text-white, 1.2);

        @media screen and (max-width: 991px) {
            text-align: center;
            margin: 1em 0;
        }
    }

    &-link {
        @include text (14px, 600, $text-white, normal);
        text-align: center;
        background-color: $main-call-btn-bgc;
        border-radius: 8pt;
        padding: 23px 47px;
        width: max-content;
        text-transform: uppercase;
    }
}