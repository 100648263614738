@import '../../assets/styles/colors.scss';
@import '../../assets/styles/mixins.scss';

.top {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: top center;
    
    
    &-img {
        background: rgb(10 10 44 / 75%);
    }

    &-filling {
        display: flex;
        flex-direction: column;
        padding: 2em 0;
        width: 60%;

        @media screen and (max-width: 991px) {
            width: 100%;
        }
    }

    &-header {
        @include text (38px, 300, $text-white, 1.2);
        margin-bottom: 0.5em;
    }

    &-text {
        @include text (14px, 400, $text-white, 2);
        margin-bottom: 3em;
    }

    &-btn {
        width: max-content;
        border-radius: 8pt;
        @include text (14px, 600, $text-white, normal);
        background-color: $bgc-blue;
        min-width: 186px;
        padding: 23px 47px;
        text-transform: uppercase;
    }
}